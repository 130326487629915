

.custom {
  width: 100;
  height: 50;
  color: "#fff";
  position: "fixed";
  bottom: "70px";
  right: "20px";
  padding: 0;
}
/* ==== */

.box {
  border-top-right-radius: 10px !important;
  border-bottom-left-radius: 20px !important;
  border-top: 1px solid #d0d0da;
  margin-top: -20px !important;
  padding: 4px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
}

/* .items{
  height:350px !important;
} */

.long-border {
  border-style: ridge !important;
  border-radius: 10px;
  padding: 20 !important;
}

.container-div {
  height: 100% !important;
  left: 2% !important;
}

